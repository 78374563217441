import { ReactNode } from "react";
import Header from "../Header";

interface AppWrapperProps {
  children: ReactNode;
}

const AppWrapper = ({ children }: AppWrapperProps) => {
  return (
    <div className="flex flex-col relative">
      <Header />
      <div className="h-full">{children}</div>
    </div>
  );
};


export default AppWrapper