import Tippy from "@tippyjs/react";

interface TooltipProps {
  content: JSX.Element | string;
  hideTooltip?: boolean;
  children: JSX.Element;
}

const Tooltip = ({ content, hideTooltip, children }: TooltipProps) => {
  if (hideTooltip) return null;
  return (
    <Tippy
      content={content}
      className="border-2 border-black rounded text-center text-xs font-normal bg-white text-black"
    >
      {children}
    </Tippy>
  );
};

export default Tooltip;
