import Projects from "../components/Projects"

const Landing = () => {
  return (
    <div className="">
      <Projects />
    </div>
  )
}

export default Landing