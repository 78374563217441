import classnames from "classnames";
import { Projects } from "../../constants/projects";

interface GalleryProps {
  selectProject: (projectId: string) => void;
  show: boolean;
}

const Gallery = ({ selectProject, show }: GalleryProps) => {
  return (
    <div
      className={classnames("mx-8", {
        "gallery-rollout": show,
        "gallery-rollin": !show,
      })}
    >
      {Projects.map((project) => (
        <section className={classnames("project-bar-perspective cursor-pointer")} onClick={() => selectProject(project.id)}>
          <div
            className={classnames(
              "flex flex-col gap-4 items-center py-4 px-1 w-fit h-full",
              project.shadow
            )}
            style={{ backgroundColor: project.primary, color: project.secondary }}
          >
            <img alt="" className="w-8 h-8" src={project.imageOrLogo} />
            <h4 className="vert-text">{project.name}</h4>
          </div>
        </section>
      ))}
    </div>
  );
};

export default Gallery;
