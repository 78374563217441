import { Context, createContext, useContext } from "react";


let context: Context<any>;

const createDataRoot = () => {
  context = createContext(undefined);
  context.displayName = "Portfolio";
  const Provider = context.Provider;

  return ({ children }: { children: JSX.Element | JSX.Element[] }) => {

    const dataContext = {};

    return <Provider value={dataContext}>{children}</Provider>;
  };
};

const PortfolioProvider = createDataRoot();

const usePortfolioStore = () => {
  return useContext(context);
};

export { PortfolioProvider, usePortfolioStore };
