import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { Project } from "../../types/projects";
import { faSitemap } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "../layout/Tooltip";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
interface ProjectLinksProps {
  project: Project;
}

const ProjectLinks = ({ project }: ProjectLinksProps) => {
  return (
    <div className={classnames("border-black py-1 px-4 w-fit rounded-lg flex gap-4")}>
      {project.githubUrl && (
        <Tooltip content="Github">
          <a href={project.githubUrl!} rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon
              icon={faGithub}
              size="2x" className="p-1"
              style={{
                color: project.secondary,
              }}
            />
          </a>
        </Tooltip>
      )}
      {project.deployedUrl && (
        <Tooltip content="Website">
          <a href={project.deployedUrl!} rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon
              icon={faSitemap}
              size="2x" className="p-1"
              style={{
                color: project.secondary,
              }}
            />
          </a>
        </Tooltip>
      )}
    </div>
  );
};

export default ProjectLinks;
