interface AboutMeProps {
  show: boolean;
}

const AboutMe = ({ show }: AboutMeProps) => {
  if (!show) return null;
  return (
    <div>
      <div>
        <h4 className="font-bold text-lg">A Journey, long in the making</h4>
        <p className="tracking-wider font-light my-2">
          I took my sweet time finding a passion to pursue. I dabbled and explored many different things,
          clocking a solid 11 years in the restuarant industry before finally discovering my "thing".
        </p>
        <p className="tracking-wider font-light my-2">
          Picking up a few online courses and discovering a real feel for it, I started to explore my options
          for learning more. After learning about the DigitalCrafts bootcamp program, I made the decision to
          take the full leap forward. Fast forward to now and I'm a Full Stack Web3 Developer, solving complex
          techinical problems and creating shipable code.
        </p>
        <p className="tracking-wider font-light my-2">
          My experience using different technologies has grown a lot as I research, discover and build more
          projects for clients. I lean heavily into React development and communicating with Web3 smart
          contracts through web3 libraries such as 'ethers' and 'web3' but can also spin create an 'Express'
          server and writing a migration, test, and/or query or two as the need arises.
        </p>
      </div>
    </div>
  );
};

export default AboutMe;
