import React from "react";
import ReactDOM from "react-dom";
import "./assets/styles/default/index.css";
import 'tippy.js/dist/tippy.css';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PortfolioProvider } from "./stores/Portfolio";

ReactDOM.render(
  <React.StrictMode>
    <PortfolioProvider>
      <App />
    </PortfolioProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
