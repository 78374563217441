import classnames from "classnames";
interface BarProps {
  label: string;
  isOpen: boolean;
  toggle: () => void;
}

const Bar = ({ label, isOpen, toggle }: BarProps) => {
  return (
    <div
      className={classnames("border-2 border-black rounded-lg w-fit cursor-pointer max-h-48 text-center", {
        "vert-text": isOpen,
        "hort-text": !isOpen,
      })}
      onClick={toggle}
    >
      <h4 className="tracking-widest font-bold">{label}</h4>
    </div>
  );
};

export default Bar;
