import { useState } from "react";
import classnames from "classnames";
import Bar from "../layout/bars/Bar";
import SubContent from "../layout/SubContent";
import ProjectMenu from "./ProjectMenu";
import AboutMe from "../AboutMe";

const Projects = () => {
  const [showProjects, setShowProjects] = useState(false);
  const [showAboutMe, setShowAboutMe] = useState(false);

  const toggleShowProjects = () => {
    setShowProjects((prevState) => !prevState);
  };
  const toggleShowAboutMe = () => {
    setShowAboutMe((prevState) => !prevState);
  };

  return (
    <div className={classnames("my-10 mx-4 flex gap-4")}>
      <Bar label="Projects" isOpen={showAboutMe || showProjects} toggle={toggleShowProjects} />
      <SubContent show={showProjects}>
        <ProjectMenu show={showProjects} />
      </SubContent>
      <Bar label="About Me" isOpen={showAboutMe || showProjects} toggle={toggleShowAboutMe} />
      <SubContent show={showAboutMe}>
        <AboutMe show={showAboutMe}/>
      </SubContent>
    </div>
  );
};

export default Projects;
