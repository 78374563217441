import { DevelopmentStatus } from "../../types/projects";
import classnames from "classnames";

const badgeStyles = (status: DevelopmentStatus) => {
  switch (status) {
    case DevelopmentStatus.Alpha:
      return "bg-blue-700 text-white";
    case DevelopmentStatus.Beta:
      return "bg-yellow-300 text-gray-400";
    case DevelopmentStatus.Archived:
      return "bg-gray-300 text-gray-500";
    case DevelopmentStatus.Development:
      return "bg-purple-800 text-white";
    case DevelopmentStatus.Production:
      return "bg-green-700 text-white";
  }
};

interface DeploymentBadgeProps {
  status: DevelopmentStatus;
}

const DeploymentBadge = ({ status }: DeploymentBadgeProps) => {
  return (
    <div className={classnames("px-4 py-1 rounded-xl border-2 border-black border-groove shadow-inner h-fit", badgeStyles(status))}>
      <p className="text-xs tracking-wider">{status}</p>
    </div>
  );
};

export default DeploymentBadge;
