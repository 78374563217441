import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "../layout/containers/Card";
import HeaderItem from "./HeaerItem";
import "@fortawesome/fontawesome-svg-core";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import Tooltip from "../layout/Tooltip";
const Header = () => {
  return (
    <div className="flex justify-center bg-slate-200 border-b-4 border-neutral-900 border-double">
      <Card>
        <div className="profile-frame"></div>
        <HeaderItem label="Name:" value="David A. Colón" />
        <HeaderItem label="Email:" value="dcolonantonio@gmail.com" />
        <HeaderItem label="Position:" value="Full Stack Web3 Developer" />
        <HeaderItem label="Status:" value="Employed" />
        <HeaderItem label="Company:" value="Decent Labs" noBorder />
        <div className="flex gap-4 justify-center mt-1">
          <Tooltip content="Github">
            <a href="https://github.com/Da-Colon" target="_blank" rel="noopener noreferrer" >
              <FontAwesomeIcon icon={faGithub} size="2x" className="p-1" />
            </a>
          </Tooltip>
          <Tooltip content="Linkedin">
            <a href="https://www.linkedin.com/in/david-a-colon/" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} size="2x" className="p-1" />
            </a>
          </Tooltip>
        </div>
      </Card>
    </div>
  );
};

export default Header;
