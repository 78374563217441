import AppWrapper from "./components/AppWrapper";
import Landing from "./pages";

function App() {
  return (
    <AppWrapper>
      <Landing />
    </AppWrapper>
  );
}

export default App;
