import classnames from "classnames";
import { ReactNode } from "react";

interface SubContentProps {
  show: boolean;
  children: ReactNode;
}

const SubContent = (props: SubContentProps) => {

  return (
    <div
      className={classnames("overflow-hidden",{
        "menu-rollout": props.show,
        "menu-rollin": !props.show,
      })}
    >
      {props.children}
    </div>
  );
};

export default SubContent;
