import classnames from 'classnames';

interface HeaderItemProps {
  label: string;
  value: string;
  noBorder?: boolean;
} 

const HeaderItem = ({label, value, noBorder}: HeaderItemProps) => (
  <div className={classnames("flex gap-4 justify-between", {
    'border-b border-gray-200 pb-1': !noBorder
  })}>
    <label className="font-bold">{label}</label>
    <p>{value}</p>
  </div>
)

export default HeaderItem;