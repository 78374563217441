import { Project } from "../../types/projects";
import classnames from "classnames";
import DeploymentBadge from "./DeploymentBadge";
import ProjectLinks from "./ProjectLinks";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

interface ProjectProps {
  project: Project;
  closeProject: () => void;
}

const ProjectUI = ({ project, closeProject }: ProjectProps) => {
  const [onRolled, setUnrolled] = useState(false);

  const onRollTimer = () => {
    setTimeout(() => setUnrolled(true), 10);
    return setUnrolled(false);
  };

  useEffect(onRollTimer, []);

  return (
    <div
      className={classnames("rounded-lg", {
        "project-rollout": onRolled,
        "project-rollin": !onRolled,
      })}
      style={{ backgroundColor: project.primary, color: project.secondary }}
    >
      <section className={classnames("project-header-perspective relative")}>
        <FontAwesomeIcon icon={faChevronLeft} className={classnames("w-6 h-6 absolute z-50 top-5 left-5 cursor-pointer", `text-${project.secondary}`)} onClick={closeProject}/>
        <div
          className={classnames("flex gap-4 items-center py-4 justify-center w-full h-full", project.shadow)}
          style={{ backgroundColor: project.primary }}
        >
          <img alt="" className="w-8 h-8" src={project.imageOrLogo} />
          <h4 className="text-2xl">{project.name}</h4>
        </div>
      </section>
      <section className="py-4 px-8">
        <div
          className={classnames("w-full mt-4 mb-8 rounded-3xl")}
          style={{ backgroundColor: project.primary }}
        >
          <img
            alt=""
            className={classnames("w-auto h-32 mx-auto")}
            src={project.imageOrLogo}
            style={{ backgroundColor: project.primary }}
          />
        </div>
        <div className="flex gap-4 items-center justify-center">
          <DeploymentBadge status={project.developmentStatus} />
          <ProjectLinks project={project} />
        </div>
        <div className="text-center">
          <p className="my-4">{project.description}</p>
          <p className="my-4">{project.additionalComment}</p>
        </div>
      </section>
    </div>
  );
};

export default ProjectUI;
