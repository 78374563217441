import yieldHunt from "../assets/images/yield-hunt-logo.svg";
import ssSanta from "../assets/images/ssSanta.png";
import whosNext from "../assets/images/logo-black.svg";
import sarcoLogo from "../assets/images/sarco-logo.png";

import { DevelopmentStatus, Project } from "../types/projects";

export const PROJECT_SARCOPHAGUS: Project = {
  id: "0",
  name: "Sarcophagus",
  imageOrLogo: sarcoLogo,
  description: "Sarcophagus is an autonomous, incentivized application built on Ethereum and Arweave.",
  githubUrl: "https://github.com/sarcophagus-org/sarcophagus-app",
  deployedUrl: "https://app.sarcophagus.io/#/tomb",
  additionalComment:
    "Typescript React, Context API, and Ethereum and Arweave blockchains brings this decentralized app to life. Tons of techincal hurdles were overcome to create this project, including retrieving accurate information from the contract in realtime without sacrificing user experience when using the app.",
  developmentStatus: DevelopmentStatus.Alpha,
  primary: "black",
  secondary: "white",
  shadow: "shadow-sarco",
};

export const PROJECT_YIELD_HUNT: Project = {
  id: "1",
  name: "Yield Hunt",
  imageOrLogo: yieldHunt,
  description: "Using data from Uniswap's Subgraph a table is created showing the APEst of Dai Pools",
  githubUrl: null,
  deployedUrl: "https://yieldhunt.decentlabs.io/#/uniswap",
  additionalComment:
    "Alot of research was done on Uniswap's Subgraph Protocol and creating the methods needed to calculate a valueable APR, powered by Apollo, React Typescript and Subgraph Protocol's GraphQL API",
  developmentStatus: DevelopmentStatus.Beta,
  primary: "rgb(88 28 135)",
  secondary: "rgb(253 224 71)",
  shadow: "shadow-yield-hunt",
};
export const PROJECT_SS_SANTA: Project = {
  id: "2",
  name: "Secret Santa NFT",
  imageOrLogo: ssSanta,
  description: "An on-chain NFT project to safely exchange gifts with the crypto community.",
  githubUrl: null,
  deployedUrl: "https://nftsecretsanta.com/",
  additionalComment:
    "Quick fun, project. Worked with two other great developers to create this site and it's contracts.",
  developmentStatus: DevelopmentStatus.Beta,
  primary: "rgb(5 150 105)",
  secondary: "white",
  shadow: "shadow-ss-nft",
};

export const PROJECT_WHOS_NEXT: Project = {
  id: "3",
  name: "Who's Next",
  imageOrLogo: whosNext,
  description:
    "A fun app that allows users to create and save lists that can be randomly selected from using fun visuals",
  githubUrl: null,
  deployedUrl: "https://nftsecretsanta.com/",
  additionalComment: "Side Project that has undergone quite a few changes since the first imagined one-pager",
  developmentStatus: DevelopmentStatus.Development,
  primary: "rgb(22 101 52)",
  secondary: "white",
  shadow: "shadow-whos-next",
};

export const Projects = [PROJECT_SARCOPHAGUS, PROJECT_YIELD_HUNT, PROJECT_SS_SANTA, PROJECT_WHOS_NEXT];
