import { ReactNode } from "react";
import classnames from 'classnames';

interface ContainerProps {
  hasBorder?: boolean;
  padding?: 'small' | 'large';
  background?: boolean;
  flex?: boolean;
  children: ReactNode;
}

const Container = (props: ContainerProps) => {
  return (
    <div className={classnames('my-4', {
      'border border-gray-300 rounder-md ': props.hasBorder
    }, {
      'p-2': props.padding === 'small',
      'py-8 px-4': props.padding === 'large'
    }, {
      '': props.background
    })}>
      {props.children}
    </div>
  )
}

export default Container;