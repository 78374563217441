export interface Project {
  id: string;
  name: string;
  imageOrLogo: string;
  description: string;
  githubUrl: string | null;
  deployedUrl: string | null;
  developmentStatus: DevelopmentStatus;
  additionalComment: string;
  primary: string;
  secondary: string;
  shadow: string;
}

export enum DevelopmentStatus {
  Development = "development",
  Beta = "beta",
  Alpha = "alpha",
  Production = "production",
  Archived = "archived",
}
