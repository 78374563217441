import { useEffect, useState } from "react";
import { Projects } from "../../constants/projects";
import { Project } from "../../types/projects";
import { ProjectMenuScreen } from "../../types/screen-state";
import Gallery from "./Gallery";
import ProjectUI from "./Project";

interface ProjectMenuProps {
  show: boolean;
}

const ProjectMenu = ({ show }: ProjectMenuProps) => {
  const [menuState, setMenuState] = useState<ProjectMenuScreen>(ProjectMenuScreen.Projects);
  const [project, setProject] = useState<Project>({} as Project);

  const selectProject = (projectId?: string) => {
    if (!projectId) {
      setProject({} as Project);
    }
    const project = Projects.find((project) => project.id === projectId);
    setProject(project!);
  };

  const updateMenuState = () => {
    if (project.id) {
      setMenuState(ProjectMenuScreen.Project);
    } else {
      setMenuState(ProjectMenuScreen.Projects);
    }
  };

  const closeMenu = () => {
    setMenuState(ProjectMenuScreen.Projects);
    setProject({} as Project);
  };

  const closedMenu = () => {
    if (!show) {
      closeMenu();
    }
  };

  useEffect(updateMenuState, [project]);
  useEffect(closedMenu, [show]);

  switch (menuState) {
    case ProjectMenuScreen.Projects:
      return <Gallery selectProject={selectProject} show={show} />;
    case ProjectMenuScreen.Project:
      return <ProjectUI project={project!} closeProject={closeMenu} />;
  }
};

export default ProjectMenu;
